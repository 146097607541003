import(/* webpackMode: "eager" */ "/home/forge/stage.aareal-aval.de/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["DataProvider"] */ "/home/forge/stage.aareal-aval.de/components/context/data-context.tsx");
;
import(/* webpackMode: "eager" */ "/home/forge/stage.aareal-aval.de/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/forge/stage.aareal-aval.de/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/home/forge/stage.aareal-aval.de/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/forge/stage.aareal-aval.de/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
